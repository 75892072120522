export enum ELayoutWidth {
  STANDARD = 'standard',
  CONTENT = 'content',
  FULL = 'full',
}

export enum EBorder {
  NONE = 'none',
  FULL_COLORSECTION = 'full_colorSection',
  TEMPLATE_BORDER_NO_PADDING = 'template_border_no_padding',
  TEMPLATE_BORDER_NO_PADDING_POST = 'template_border_no_padding_post',
  TEMPLATE_BORDER_PADDING = 'template_border_padding',
}

export enum ELayoutSpacing {
  STANDARD = 'standard',
  NONE = 'none',
}

export enum ELayoutPadding {
  STANDARD = 'standard',
  NONE = 'none',
  ONLY_TOP = 'only_top',
  ONLY_BOTTOM = 'only_bottom',
  ONLY_BOTTOM_LG = 'only_bottom_lg',
  TEMPLATE = 'template',
  TEMPLATE_WITH_GALLERY = 'template_with_gallery',
}
